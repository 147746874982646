import { FC, useCallback, useMemo } from 'react';
import ActionBaseProps from '../ActionBaseProps';
import AddressInput from '../../../shared/form-control/AddressInput';
import AddressLocation from '../../../../models/AddressLocation';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionTitleDescription from '../ActionTitleDescription';

const AddressAction: FC<ActionBaseProps<AddressLocation>> = (props) => {
  const { response, required, data } = props;
  const { onAnswer, readOnly } = useFormAction(props);

  const { t } = useTranslation('activity-type');

  const onAddressChanged = useCallback(
    (address: AddressLocation) => {
      onAnswer(address);
    },
    [onAnswer],
  );

  const onClear = useCallback(() => {
    onAnswer(null);
  }, [onAnswer]);

  const placeholder = useMemo(() => {
    return !response ? t('address.placeholder') : response.address;
  }, [response, t]);

  return (
    <div data-cy="address-action">
      <ActionTitleDescription required={required} {...data} />
      <AddressInput
        onAddressChanged={onAddressChanged}
        initialValue={response?.address}
        placeholder={placeholder}
        onClear={onClear}
        disabled={readOnly}
      />
    </div>
  );
};

export default AddressAction;
